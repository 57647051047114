import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import ButtonGhost from "../Button/ButtonGhost";

const About = ({ className }) => {
   const data = useStaticQuery(graphql`
      {
         about: file(relativePath: { eq: "repeating/about/About us.jpg" }) {
            childImageSharp {
               gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
            }
         }
      }
   `);

   return (
      <section className={`${className}`}>
         <div className="container">
            <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20 items-center">
               <div>
                  <GatsbyImage image={data.about.childImageSharp.gatsbyImageData} alt="About" />
               </div>
               <div>
                  <h2>Your Small Business Accounting Experts</h2>
                  <p>
                     Smart Accounting Hub exists to help small business owners and individuals get organized with their finances and move towards
                     health. Worrying about financial processes and keeping up with the books can drain time and energy from busy owners. Lean on our
                     small business accounting services so you can keep working on what you do best.
                  </p>
                  <ButtonGhost href="/about/" text="Learn More" />
               </div>
            </div>
         </div>
      </section>
   );
};

export default About;
