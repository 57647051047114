import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";
import HeroFullWidth from "../components/Hero/HeroFullWidth";
import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
   const heroImages = [
      getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
      {
         ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
         media: `(max-width: 767px)`,
      },
   ];

   return (
      <Layout headerStyle="standard" headerLinkColor="" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Small Business Bookkeeping Services San Diego | Smart"
            description="Smart Accounting Hub offers top small business accounting services in San Diego. From bookkeeping to business management, get a quote & see how we can help!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroFullWidth backgroundImages={heroImages} padding="pt-48 md:pt-44 pb-16 md:pb-40" textAlignment="text-left" textMaxWidth="max-w-2xl">
            <header className="relative">
               <p className="font-heading text-mobile-7xl md:text-7xl text-white font-bold mb-4 md:mb-6">
                  When it comes to your finances, we take care of business.
               </p>
               <p className="text-base md:text-xl text-white">
                  We streamline financial processes, reports, and bookkeeping for small businesses in every industry.
               </p>
               <ButtonSolid as="button" modal="modal-contact" text="Get a Quote" />
            </header>
         </HeroFullWidth>

         <section className="py-16 md:py-22">
            <div className="container">
               <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20 items-center">
                  <div className="order-2 md:order-1">
                     <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} alt="Intro" />
                  </div>
                  <div className="order-1 md:order-2">
                     <h1>San Diego Small Business Accounting Services</h1>
                     <p>
                        Businesses can succeed or fail based on how well they manage their finances. Our goal is to take the burden of financial
                        planning off our clients’ minds so they can focus on growing their business. Smart Accounting Hub offers small business
                        accounting services with a personal touch throughout San Diego to help you thrive.
                     </p>
                     <ButtonGhost href="/services/" text="Learn More" />
                  </div>
               </div>
            </div>
         </section>

         <section className="bg-gray-100 pt-16 md:pt-18 pb-20 md:pb-32">
            <div className="container">
               <header className="mb-16 md:mb-20 max-w-3xl">
                  <h2>Smart Services for Small Businesses</h2>
                  <p className="text-xl md:text-xl mb-0">
                     No matter what industry you’re in, our bookkeeping and business management services will position you for present and future
                     success.
                  </p>
               </header>
               <div className="mb-20 md:mb-32">
                  <div className="grid md:grid-cols-12 gap-y-7 md:gap-x-12 lg:gap-x-18 xl:gap-x-26 items-center">
                     <div className="md:col-start-1 md:col-span-7">
                        <GatsbyImage image={data.personalBookkeeping.childImageSharp.gatsbyImageData} alt="Personal Bookkeeping" />
                     </div>
                     <div className="md:col-end-13 md:col-span-5">
                        <h3>Personal Bookkeeping</h3>
                        <p>
                           For busy business owners and other individuals, our personal bookkeeping services will keep you on track personally so you
                           can thrive professionally.
                        </p>
                        <ButtonGhost href="/personal-bookkeeping-services/" text="Learn More" />
                     </div>
                  </div>
               </div>

               <div className="mb-20 md:mb-32">
                  <div className="grid md:grid-cols-12 gap-y-7 md:gap-x-12 lg:gap-x-18 xl:gap-x-26 items-center">
                     <div className="order-2 md:order-1 md:col-start-1 md:col-span-5">
                        <h3>Business Bookkeeping</h3>
                        <p>
                           We’ll reconcile your revenue, expenses, and bank statements each month and meet with you on your schedule. Get organized so
                           you can be more profitable.
                        </p>
                        <ButtonGhost href="/business-bookkeeping-services/" text="Learn More" />
                     </div>
                     <div className="order-1 md:order-2 md:col-end-13 md:col-span-7">
                        <GatsbyImage image={data.businessBookkeeping.childImageSharp.gatsbyImageData} alt="Business Bookkeeping" />
                     </div>
                  </div>
               </div>

               <div className="mb-20 md:mb-32">
                  <div className="grid md:grid-cols-12 gap-y-7 md:gap-x-12 lg:gap-x-18 xl:gap-x-26 items-center">
                     <div className="md:col-start-1 md:col-span-7">
                        <GatsbyImage image={data.businessManagement.childImageSharp.gatsbyImageData} alt="Business Management" />
                     </div>
                     <div className="md:col-end-13 md:col-span-5">
                        <h3>Business Management</h3>
                        <p>
                           We organize or create new processes to help your business operations be more efficient. Wherever you need assistance, we
                           can fill in the gaps.
                        </p>
                        <ButtonGhost href="/small-business-management-services/" text="Learn More" />
                     </div>
                  </div>
               </div>

               <div>
                  <div className="grid md:grid-cols-12 gap-y-7 md:gap-x-12 lg:gap-x-18 xl:gap-x-26 items-center">
                     <div className="order-2 md:order-1 md:col-start-1 md:col-span-5">
                        <h3>Financial Reporting</h3>
                        <p>
                           Cash flow management is more important than a bank balance or revenue growth. We’ll ensure this is done correctly to keep
                           your business running smoothly.
                        </p>
                        <ButtonGhost href="/financial-reporting-services/" text="Learn More" />
                     </div>
                     <div className="order-1 md:order-2 md:col-end-13 md:col-span-7">
                        <GatsbyImage image={data.financialReporting.childImageSharp.gatsbyImageData} alt="Financial Reporting" />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Testimonials className="py-20 md:py-32" />

         <WhyUs />

         <About className="py-20 md:py-32" />

         <CallToAction className="py-20 md:py-32" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Home.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Home.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "home/1.0 Hero-desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      heroMobile: file(relativePath: { eq: "home/1.0 Hero-mobile.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      intro: file(relativePath: { eq: "home/2.0 Intro.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      personalBookkeeping: file(relativePath: { eq: "home/3.0 Personal Bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessBookkeeping: file(relativePath: { eq: "home/4.0 Business bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessManagement: file(relativePath: { eq: "home/5.0 Business Managment.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      financialReporting: file(relativePath: { eq: "home/6.0 Financial Reporting.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
